<template>
    <div>
        <b-card>
            <OwnersTransactionsAceptanceFilters
                :perPage="perPage"
                :perPageOptions="perPageOptions"
                :searchQuery="searchQuery"
                :requests="transactionsAgreementsList"
                :isLoadingData="isLoadingAgreements"
                @change-per-page="changePerPage"
                @search-in-table-records="searchInTableRecords"
                @look-per-filter="filteredSearch"
                @reset-filter="resetFilter"
            />
            <b-table
                v-if="filteredAceptances.length > 0 && !isLoadingAgreements"
                :items="filteredAceptances"
                :fields="tableColumns"
                responsive
                small
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                :sort-desc.sync="isSortDirDesc"
                :busy.sync="isLoadingAgreements"
                primary-key="id"
                sticky-header="400px"
                show-empty
                class="position-relative mb-0 "
            >

                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                        <strong> Cargando...</strong>
                    </div>
                </template>
                <template #cell(code)="row">
                    <b-link
                        v-if="can('fivesclub_web_owners_show_owner_transacctions_agreements_details_view')"
                        :to="{ name: 'owners-transactions-aceptance-detail', params: { id: row.item.id} }"
                        class="font-weight-bolder link-text"
                    > {{ row.item.code }}
                    </b-link>
                    <span v-else>{{ row.item.code }}</span>
                </template>
                <template #cell(housingnumber)="row">
                    <b-link
                        v-if="can('fivesclub_web_owners_show_owner_transacctions_agreements_details_view')"
                        :to="{ name: 'owners-transactions-aceptance-detail', params: { id: row.item.id} }"
                        class="font-weight-bolder link-text"
                    > {{ row.item.housingnumber }}
                    </b-link>
                    <span v-else>{{ row.item.housingnumber }}</span>
                </template>
                <template #cell(statusName)="row">
                    <b-badge pill :class="badgeStatus(row.item)">{{row.item.statusName}}</b-badge>
                </template>
                <template #cell(reviewStatusName)="row">
                    <b-badge pill :class="badgeReviewStatus(row. item)" v-if="row.item.reviewStatus">{{row.item.reviewStatusName}}</b-badge>
                    <span v-else>N/A</span>
                </template>
            </b-table>

            <div class="text-center my-2" v-else-if="isLoadingAgreements">
                <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                <strong> Cargando...</strong>
            </div>

            <div v-else>
                <b-alert show v-if="!notFoundData" variant="warning" class="p-1 mt-2">
                    <feather-icon icon="InfoIcon" class="mr-50" />
                    <span>Haga una busqueda mediante el filtro</span>
                </b-alert>
                <b-alert show v-if="notFoundData" variant="warning" class="p-1 mt-2">
                    <feather-icon icon="InfoIcon" class="mr-50" />
                    <span><strong>¡Sin registros!</strong> No se encontraron registros.</span>
                </b-alert>
            </div>

            <PaginationTable
                class="mt-1"
                :currentPage="currentPage"
                :totalRows="transactionsAgreementsList.length"
                :perPage="perPage"
                @change-page="changePage"
                v-show="transactionsAgreementsList.length"
            />
        </b-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive'
import { acl } from "@/modules/auth/mixins/acl"
import * as moment from "moment"
import OwnersTransactionsAceptanceFilters from "@/modules/fivesClub/components/catalogs/ownersWebsite/OwnersTransactionsAceptanceFilters"
import PaginationTable from "@/modules/fivesClub/components/catalogs/ownersWebsite/PaginationTable"

export default {
    mixins: [acl],
    directives: { Ripple, },
    components: {
        OwnersTransactionsAceptanceFilters,
        PaginationTable
    },
    async created(){
        await this.init()
    },
    data(){
        return {
            isLoadingInitialData: false,
            isLoadingAgreements: false,
            tableColumns: [
                { key:'code', class:'text-center', tdClass: 'p-1', label: 'Id de la transacción'},
                // { key:'reviewedLog', class:'text-center', tdClass: 'p-1', label: 'Id Relacionado'},
                { key:'resortsname', class:'text-center', tdClass: 'p-1', label: 'Resort'},
                { key:'housingnumber', class:'text-center', tdClass: 'p-1', label: 'Vivienda'},
                { key:'contractCode', class:'text-center', tdClass: 'p-1', label: 'Contrato'},
                { key:'membershipcode', class:'text-center', tdClass: 'p-1', label: 'Membresía'},
                { key:'ownerName', class:'text-center', tdClass: 'p-1', label: 'Propietario'},
                { key:'signDate', class:'text-center', tdClass: 'p-1', label: 'Fecha de Transacción'},
                { key:'periodName', sortable: true, class:'text-center', tdClass: 'p-1', label: 'Periodo', formatter: (value, key, item) => {
                    return `${item.periodId}° periodo ${item.periodName} del año ${this.year(item.periodInitDate)}`
                }},
                { key:'statusName', class:'text-center', tdClass: 'p-1', label: 'Estado'},
                { key:'reviewStatusName', class:'text-center', tdClass: 'p-1', label: 'Resolución'},
            ],
            filterOn: ["contractCode", "ownerName", 'housingnumber', 'membershipcode', 'signDate', 'statusName' ],
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20, 25, 50, 100],
            searchQuery: '',
            sortBy: 'id',
            isSortDirDesc: true,
            notFoundData: false,
        }
    },
    computed: {
        // status 4, es rechazada
        ...mapState('start', ["hotels"]),
        ...mapState("auth", ["user"]),
        ...mapState("fivesClubCatalogs", ["transactionsAgreementsList"]),
        filteredAceptances(){
            const items = this.transactionsAgreementsList
            return items.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
        },
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ["fetchOwnerTransactionsAcceptance", "fetchOwnerTransactionsAcceptanceExcel", "fetchMembershipsInfo", "getInitialContentForOwnersTransactionsAceptance"]),
        ...mapMutations('fivesClubCatalogs',['setTransactionsAgreementsList', 'setMembershipsTitles']),
        ...mapMutations('start',['setHotels']),
        async init(){
            this.isLoadingInitialData = true

            await this.getInitialContentForOwnersTransactionsAceptance({
                hotels: !this.hotels?.length,
                memberships: true
            })

            this.isLoadingInitialData = false

            if (this.$route.params?.reload) {
                this.isLoadingAgreements = true
                const agreements = await this.fetchOwnerTransactionsAcceptance()
                if (agreements?.length) {
                    agreements.sort( (a,b) => { return new Date(b.createDate) - new Date(a.createDate) })
                    this.setTransactionsAgreementsList(agreements)
                } else this.setTransactionsAgreementsList([])
                this.isLoadingAgreements = false
            }
        },
        changePage(page){
            this.currentPage = page
        },
        changePerPage(perPage){
            this.perPage = perPage
        },
        searchInTableRecords(searchQuery){
            this.searchQuery = searchQuery
        },
        year(date){
            return moment(date || new Date()).format("YYYY")
        },
        async filteredSearch(payload){
            this.isLoadingAgreements = true
            if (payload.excel) await this.fetchOwnerTransactionsAcceptanceExcel(payload)
            else {
                const agreements = await this.fetchOwnerTransactionsAcceptance(payload)
                if (agreements) {
                    agreements.sort( (a,b) => { return new Date(b.createDate) - new Date(a.createDate) })
                    this.setTransactionsAgreementsList(agreements)
                } else this.setTransactionsAgreementsList([])
                this.notFoundData = !agreements?.length > 0
            }
            this.isLoadingAgreements = false
        },
        resetFilter(){
            this.setTransactionsAgreementsList([])
            this.notFoundData = false
        },
        badgeStatus(data){
            const { status } = data

            let variant =  'bg-success'
            // if(status === '1' ) variant = 'bg-info'
            if(status === '1' ) variant = 'bg-success'
            if( status === '2' ) variant = 'bg-danger'
            // if( status === '4' ) variant = 'bg-warning'

            return variant
        },
        badgeReviewStatus(data){
            const { reviewStatus } = data

            let variant =  'bg-success'
            if(reviewStatus === '1' ) variant = 'btn-warning'
            if( reviewStatus === '2' ) variant = 'btn-outline-success'
            if(reviewStatus === '3' ) variant = 'btn-success'
            // if( reviewStatus === '4' ) variant = 'bg-danger'

            return variant
        }
    },
};
</script>

<style scoped>
.head-btn{
display: flex;
justify-content: flex-end;
margin-bottom: 1rem;
}

.actions-status{
display: flex;
align-items: center;
gap: 1rem;
}

.table-text{
    font-size: 0.8rem;
}
.link-text{
    text-decoration: underline solid 1.5px !important;
}
</style>
